import React from 'react';
import Layout from '../../../Components/Layout/Layout';
import './Live.scss';
/**
 * @namespace Live
 * @function Live
 * @author Trevor Cash
 * @since 11/06/21
 * @version 1.0.0
 * @component
 */
export default function Live() {
  return (
    <Layout
      className='Live-Container'
      title='Live'
      description="First Baptist Church at West Jefferson's live feed of our Sunday worship"
    >
      <h1>Live Sermon</h1>
      <div class='sermon-embed-player'>
        <iframe
          title='test'
          className='PastSermons'
          src='https://fbcwjohio.sermon.net/player/live/21452540?repeat=false&autostart=true&counter_color=rgba(0%2C0%2C0%2C1)&counter_background=rgba(255%2C255%2C255%2C1)'
          frameborder='0'
          width='100%'
          height='100%'
          scrolling='no'
          allowTransparency='true'
          allowfullscreen='true'
        ></iframe>
      </div>
    </Layout>
  );
}
